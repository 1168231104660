.producto-nombre
{
    display: flex;
    font-weight: 600;
    height: min-content;
}

.producto-precio
{   
    margin-left: auto;
    font-weight:600;
    text-align: end;
    height: min-content;
}

.producto-descripcion
{
    display: flex;
    margin: 0 5px;
    color: rgb(99, 99, 99);
    grid-column: 2 span;
    white-space: nowrap;
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
}

.Cantidad-productoModal
{
    display: flex;
    align-items: center;
}

@media screen and (max-width: 678px) 
{
    .producto-container
    {
        grid-template-columns: 25% auto;
    }
}

.Empanada-container
{
    display: flex;
    justify-content: center;
    align-items: center;
    margin:15px 0;
}