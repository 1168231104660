.home-container-mobile
{
    display: block;
}  

.container-categoriasDesktop
{
    display: grid;
    grid-template-columns: 50% 50%;
    height: 100vh;
    overflow-y: scroll;
}

.MuiAccordionSummary-content
{
    margin:0
}

.categoria-imagen
{
    display: flex;
    margin: 0 auto;
    width: 100%;
}

@media only screen and (max-width: 1000px) 
{
    .container-categorias 
    {
        grid-template-columns: 100%;
    }
}

.icon-show
{
    position: absolute;
    top: 0;
    right: 0;
}

.icon-hide
{
    display: none;
    position: absolute;
    top: 0;
    right: 0;
}
  

.image-appbar
{
    align-self:center;
    height: 80px;
}
